import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import filter from "./modules/filter";
import uniq from "./modules/uniq";
import points from "./modules/points";
import router from "@/router";

// localStorage.clear();

Vue.use(Vuex);
const tokenName = "mapToken13042023",
  tokenRefreshName = "refreshMapToken13042023",
  tokenLocal = localStorage.getItem(tokenName),
  refreshTokenLocal = localStorage.getItem(tokenRefreshName);

export default new Vuex.Store({
  state: {
    tokenName,
    token: tokenLocal ? tokenLocal : "",
    refreshToken: refreshTokenLocal ? refreshTokenLocal : "",
    online: true,
    openClient: false,
    drivers: [],
    driversOnline: [],
    loading: false
  },
  mutations: {
    SET_TOKEN(state, value) {
      state.token = value;
      localStorage.setItem(tokenName, value);
    },
    SET_TOKEN_REFRESH(state, value) {
      state.refreshToken = value;
      localStorage.setItem(tokenRefreshName, value);
    },
    SET_REMOVE_TOKENS(state) {
      state.token = "";
      localStorage.removeItem(tokenName);
      state.refreshToken = "";
      localStorage.removeItem(tokenRefreshName);
    },
    SET_ONLINE(state, value) {
      state.online = value;
    },
    SET_OPEN_CLIENT(state, value) {
      state.openClient = value;
    },
    SET_FILTERED_DRIVERS(state, value) {
      state.drivers = value;
    },
    SET_FILTERED_DRIVERS_ONLINE(state, value) {
      state.driversOnline = value;
    },
    SET_LOADING(state, value) {
      state.loading = value;
    }
  },
  actions: {
    getToken({ commit }, value) {
      commit("SET_TOKEN", value);
    },
    getTokenRefresh({ commit }, value) {
      commit("SET_TOKEN_REFRESH", value);
    },
    async refreshTokenHandler({ commit }) {
      return await axios
        .post("/api/token/refresh", {
          refresh_token: this.state.refreshToken
        })
        .then((res) => {
          commit("SET_TOKEN", res.data.token);
          commit("SET_TOKEN_REFRESH", res.data.refresh_token);
        })
        .catch((error) => {
          const { code, message } = error.response.data;
          if (code === 401) {
            router.push("/auth");
            commit("SET_REMOVE_TOKENS");
          }
          console.log("Error!!! " + message);
        });
    },
    removeTokens({ commit }) {
      commit("SET_REMOVE_TOKENS");
    },
    changeOnline({ commit }, value) {
      commit("SET_ONLINE", value);
    },
    changeOpenClient({ commit }, value) {
      commit("SET_OPEN_CLIENT", value);
    },
    addDrivers({ commit }, value) {
      commit("SET_FILTERED_DRIVERS", value);
    },
    addDriversOnline({ commit }, value) {
      commit("SET_FILTERED_DRIVERS_ONLINE", value);
    },
    changeLoading({ commit }, value) {
      commit("SET_LOADING", value);
    }
  },
  modules: {
    filter,
    uniq,
    points
  }
});
