export default {
  state: {
    uniqAutopark: [],
    uniqBoxVolume: [],
    uniqDispatcher: [],
    uniqPalletPlace: [],
    uniqPass: [],
    uniqRegion: [],
    uniqStatus: [],
    uniqTonnage: [],
    uniqBoxType: [],
    uniqUnit: [],
    uniqClient: [],
    uniqBooleanData: ["Есть", "Нет"],
    uniqBooleanOnline: ["Да", "Нет"],
    uniqBooleanDriverFree: [
      "Занят сегодня",
      "Занят завтра",
      "Свободен сегодня",
      "Свободен завтра"
    ]
  },
  mutations: {
    // SET uniqAutopark
    SET_STATE_UNIQ_AUTOPARK: (state, value) => {
      state.uniqAutopark = value;
    },
    // SET uniqBoxVolume
    SET_STATE_UNIQ_BOX_VOLUNE: (state, value) => {
      state.uniqBoxVolume = value;
    },
    // SET uniqDispatcher
    SET_STATE_UNIQ_DISPATCHER: (state, value) => {
      state.uniqDispatcher = value;
    },
    // SET uniqPalletPlace
    SET_STATE_UNIQ_PALET_PLACE: (state, value) => {
      state.uniqPalletPlace = value;
    },
    // SET uniqPass
    SET_STATE_UNIQ_PASS: (state, value) => {
      state.uniqPass = value;
    },
    // SET uniqRegion
    SET_STATE_UNIQ_REGION: (state, value) => {
      state.uniqRegion = value;
    },
    // SET uniqStatus
    SET_STATE_UNIQ_STATUS: (state, value) => {
      state.uniqStatus = value;
    },
    // SET uniqTonnage
    SET_STATE_UNIQ_TONNAGE: (state, value) => {
      state.uniqTonnage = value;
    },
    // SET uniqBoxType
    SET_STATE_UNIQ_BOX_TYPE: (state, value) => {
      state.uniqBoxType = value;
    },
    // SET uniqUnit
    SET_STATE_UNIQ_UNIT: (state, value) => {
      state.uniqUnit = value;
    },
    // SET uniqClient
    SET_STATE_UNIQ_CLIENT: (state, value) => {
      state.uniqClient = value;
    }
  },
  actions: {
    // ADD uniqAutopark
    addUniqAutopark({ commit }, value) {
      commit("SET_STATE_UNIQ_AUTOPARK", value);
    },
    // ADD uniqBoxVolume
    addUniqBoxVolume({ commit }, value) {
      commit("SET_STATE_UNIQ_BOX_VOLUNE", value);
    },
    // ADD uniqDispatcher
    addUniqDispatcher({ commit }, value) {
      commit("SET_STATE_UNIQ_DISPATCHER", value);
    },
    // ADD uniqPalletPlace
    addUniqPalletPlace({ commit }, value) {
      commit("SET_STATE_UNIQ_PALET_PLACE", value);
    },
    // ADD uniqPass
    addUniqPass({ commit }, value) {
      commit("SET_STATE_UNIQ_PASS", value);
    },
    // ADD uniqRegion
    addUniqRegion({ commit }, value) {
      commit("SET_STATE_UNIQ_REGION", value);
    },
    // ADD uniqStatus
    addUniqStatus({ commit }, value) {
      commit("SET_STATE_UNIQ_STATUS", value);
    },
    // ADD uniqTonnage
    addUniqTonnage({ commit }, value) {
      commit("SET_STATE_UNIQ_TONNAGE", value);
    },
    // ADD uniqBoxType
    addUniqBoxType({ commit }, value) {
      commit("SET_STATE_UNIQ_BOX_TYPE", value);
    },
    // ADD uniqUnit
    addUniqUnit({ commit }, value) {
      commit("SET_STATE_UNIQ_UNIT", value);
    },
    // ADD uniqClient
    addUniqClient({ commit }, value) {
      commit("SET_STATE_UNIQ_CLIENT", value);
    }
  }
};
