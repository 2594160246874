import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
// Leaflet
import {
  LMap,
  LTileLayer,
  LMarker,
  LIcon,
  LTooltip,
  LPopup,
  LControlZoom,
  LControlAttribution
} from "vue2-leaflet";

import "@/assets/scss/index.scss";
import "leaflet/dist/leaflet.css";

Vue.config.productionTip = false;

axios.defaults.baseURL = "https://erp.tkglp.ru";
axios.defaults.withCredentials = true;
Vue.use(VueAxios, axios);

Vue.component("l-map", LMap);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-marker", LMarker);
Vue.component("l-icon", LIcon);
Vue.component("l-tooltip", LTooltip);
Vue.component("l-popup", LPopup);
Vue.component("l-control-zoom", LControlZoom);
Vue.component("l-control-attribution", LControlAttribution);

new Vue({
  store,
  router,
  render: (h) => h(App)
}).$mount("#app");
