const filterName = "mapFilter28082023",
  filter = JSON.parse(localStorage.getItem(filterName));

export default {
  state: {
    filter: filter
      ? filter
      : {
          search: "",
          selectedOrderStatus: [],
          selectedDispatcher: [],
          selectedAutopark: [],
          selectedRegion: [],
          selectedTonnage: [],
          selectedBoxType: [],
          selectedBoxVolume: [],
          selectedPalletPlace: [],
          selectedPass: [],
          selectedMedBook: [],
          selectedSanproc: [],
          selectedHydrolift: [],
          selectedUnit: [],
          selectedIP: [],
          selectedActive: [],
          selectedOnline: [],
          selectedDriverFree: [],
          selectedTg: [],
          selectedDevice: [],
          selectedClient: [],
          selectedClientLocation: []
        }
  },
  mutations: {
    // Обновление данных в LocalStorage
    SET_STATE_FILTER_CLIENTLOCATION: (state, value) => {
      state.filter.selectedClientLocation = value;
      localStorage.setItem(filterName, JSON.stringify(state.filter));
    },
    SET_STATE_FILTER: (state) => {
      localStorage.setItem(filterName, JSON.stringify(state.filter));
    },
    // Изменение search
    SET_STATE_FILTER_SEARCH: (state, value) => {
      state.filter.search = value;
      localStorage.setItem(filterName, JSON.stringify(state.filter));
    },
    // Изменение selectedOrderStatus
    SET_STATE_FILTER_STATUS: (state, value) => {
      state.filter.selectedOrderStatus = value;
      localStorage.setItem(filterName, JSON.stringify(state.filter));
    },
    // Изменение selectedDispatcher
    SET_STATE_FILTER_DISPATCHER: (state, value) => {
      state.filter.selectedDispatcher = value;
      localStorage.setItem(filterName, JSON.stringify(state.filter));
    },
    // Изменение selectedAutopark
    SET_STATE_FILTER_AUTOPARK: (state, value) => {
      state.filter.selectedAutopark = value;
      localStorage.setItem(filterName, JSON.stringify(state.filter));
    },
    // Изменение selectedRegion
    SET_STATE_FILTER_REGION: (state, value) => {
      state.filter.selectedRegion = value;
      localStorage.setItem(filterName, JSON.stringify(state.filter));
    },
    // Изменение selectedTonnage
    SET_STATE_FILTER_TONNAGE: (state, value) => {
      state.filter.selectedTonnage = value;
      localStorage.setItem(filterName, JSON.stringify(state.filter));
    },
    // Изменение selectedBoxType
    SET_STATE_FILTER_BOX_TYPE: (state, value) => {
      state.filter.selectedBoxType = value;
      localStorage.setItem(filterName, JSON.stringify(state.filter));
    },
    // Изменение selectedBoxVolume
    SET_STATE_FILTER_BOX_VOLUME: (state, value) => {
      state.filter.selectedBoxVolume = value;
      localStorage.setItem(filterName, JSON.stringify(state.filter));
    },
    // Изменение selectedPalletPlace
    SET_STATE_FILTER_PALLET_PLACE: (state, value) => {
      state.filter.selectedPalletPlace = value;
      localStorage.setItem(filterName, JSON.stringify(state.filter));
    },
    // Изменение selectedPass
    SET_STATE_FILTER_PASS: (state, value) => {
      state.filter.selectedPass = value;
      localStorage.setItem(filterName, JSON.stringify(state.filter));
    },
    // Изменение selectedMedBook
    SET_STATE_FILTER_MED_BOOK: (state, value) => {
      state.filter.selectedMedBook = value;
      localStorage.setItem(filterName, JSON.stringify(state.filter));
    },
    // Изменение selectedSanproc
    SET_STATE_FILTER_SANPROC: (state, value) => {
      state.filter.selectedSanproc = value;
      localStorage.setItem(filterName, JSON.stringify(state.filter));
    },
    // Изменение selectedHydrolift
    SET_STATE_FILTER_HYDROLIFT: (state, value) => {
      state.filter.selectedHydrolift = value;
      localStorage.setItem(filterName, JSON.stringify(state.filter));
    },
    // Изменение selectedUnit
    SET_STATE_FILTER_UNIT: (state, value) => {
      state.filter.selectedUnit = value;
      localStorage.setItem(filterName, JSON.stringify(state.filter));
    },
    // Изменение selectedIP
    SET_STATE_FILTER_IP: (state, value) => {
      state.filter.selectedIP = value;
      localStorage.setItem(filterName, JSON.stringify(state.filter));
    },
    // Изменение selectedActive
    SET_STATE_FILTER_ACTIVE: (state, value) => {
      state.filter.selectedActive = value;
      localStorage.setItem(filterName, JSON.stringify(state.filter));
    },
    // Изменение selectedOnline
    SET_STATE_FILTER_ONLINE: (state, value) => {
      state.filter.selectedOnline = value;
      localStorage.setItem(filterName, JSON.stringify(state.filter));
    },
    SET_STATE_FILTER_TG: (state, value) => {
      state.filter.selectedTg = value;
      localStorage.setItem(filterName, JSON.stringify(state.filter));
    },
    // Изменение selectedDriverFree
    SET_STATE_FILTER_DRIVERFREE: (state, value) => {
      state.filter.selectedDriverFree = value;
      localStorage.setItem(filterName, JSON.stringify(state.filter));
    },
    // Изменение selectedDevice
    SET_STATE_FILTER_DEVICE: (state, value) => {
      state.filter.selectedDevice = value;
      localStorage.setItem(filterName, JSON.stringify(state.filter));
    },
    // Изменение selectedClient
    SET_STATE_FILTER_CLIENT: (state, value) => {
      state.filter.selectedClient = value;
      localStorage.setItem(filterName, JSON.stringify(state.filter));
    }
  },
  actions: {
    // Добавление фильтрации
    addStateFilter({ commit }, value) {
      commit("SET_STATE_FILTER", value);
    },
    // Добавление search
    addStateFilterClientLocation({ commit }, value) {
      commit("SET_STATE_FILTER_CLIENTLOCATION", value);
    },
    addStateFilterSearch({ commit }, value) {
      commit("SET_STATE_FILTER_SEARCH", value);
    },
    // Добавление selectedOrderStatus
    addStateFilterStatus({ commit }, value) {
      commit("SET_STATE_FILTER_STATUS", value);
    },
    // Добавление selectedDispatcher
    addStateFilterDispatcher({ commit }, value) {
      commit("SET_STATE_FILTER_DISPATCHER", value);
    },
    // Добавление selectedAutopark
    addStateFilterAutopark({ commit }, value) {
      commit("SET_STATE_FILTER_AUTOPARK", value);
    },
    // Добавление selectedRegion
    addStateFilterRegion({ commit }, value) {
      commit("SET_STATE_FILTER_REGION", value);
    },
    // Добавление selectedTonnage
    addStateFilterTonnage({ commit }, value) {
      commit("SET_STATE_FILTER_TONNAGE", value);
    },
    // Добавление selectedBoxType
    addStateFilterBoxType({ commit }, value) {
      commit("SET_STATE_FILTER_BOX_TYPE", value);
    },
    // Добавление selectedBoxVolume
    addStateFilterBoxVolume({ commit }, value) {
      commit("SET_STATE_FILTER_BOX_VOLUME", value);
    },
    // Добавление selectedPalletPlace
    addStateFilterPalletPlace({ commit }, value) {
      commit("SET_STATE_FILTER_PALLET_PLACE", value);
    },
    // Добавление selectedPass
    addStateFilterPass({ commit }, value) {
      commit("SET_STATE_FILTER_PASS", value);
    },
    // Добавление selectedMedBook
    addStateFilterMedBook({ commit }, value) {
      commit("SET_STATE_FILTER_MED_BOOK", value);
    },
    // Добавление selectedSanproc
    addStateFilterSanproc({ commit }, value) {
      commit("SET_STATE_FILTER_SANPROC", value);
    },
    // Добавление selectedHydrolift
    addStateFilterHydrolift({ commit }, value) {
      commit("SET_STATE_FILTER_HYDROLIFT", value);
    },
    // Добавление selectedUnit
    addStateFilterUnit({ commit }, value) {
      commit("SET_STATE_FILTER_UNIT", value);
    },
    // Добавление selectedIP
    addStateFilterIP({ commit }, value) {
      commit("SET_STATE_FILTER_IP", value);
    },
    // Добавление selectedActive
    addStateFilterActive({ commit }, value) {
      commit("SET_STATE_FILTER_ACTIVE", value);
    },
    // Добавление selectedOnline
    addStateFilterOnline({ commit }, value) {
      commit("SET_STATE_FILTER_ONLINE", value);
    },
    addStateFilterTg({ commit }, value) {
      commit("SET_STATE_FILTER_TG", value);
    },
    // Добавление selectedDriverFree
    addStateFilterDriverFree({ commit }, value) {
      commit("SET_STATE_FILTER_DRIVERFREE", value);
    },
    // Добавление selectedDevice
    addStateFilterDevice({ commit }, value) {
      commit("SET_STATE_FILTER_DEVICE", value);
    },
    // Добавление selectedClient
    addStateFilterClient({ commit }, value) {
      commit("SET_STATE_FILTER_CLIENT", value);
    }
  }
};
