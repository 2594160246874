import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    meta: { layout: "home" },
    component: () => import("../views/Home.vue")
  },
  {
    path: "/test",
    name: "test",
    meta: { layout: "home" },
    component: () => import("../views/Test.vue")
  },
  {
    path: "/filter",
    name: "filter",
    meta: { layout: "home" },
    component: () => import("../views/Filter.vue")
  },
  {
    path: "/auth",
    name: "auth",
    meta: { layout: "auth" },
    component: () => import("../views/Auth.vue")
  },
  {
    path: "/*",
    name: "404",
    meta: { layout: "home" },
    component: () => import("../views/404.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/auth"],
    authRequired = !publicPages.includes(to.path),
    loggedIn = localStorage.getItem(store.state.tokenName);

  if (authRequired && !loggedIn) {
    return next("/auth");
  }

  next();
});

export default router;
