export default {
  state: {
    points: [],
    pointsClients: [],
    filter: {
      selectedClient: [],
      selectedTonnage: [],
      selectedBoxType: []
    }
  },
  mutations: {
    SET_POINTS(state, value) {
      state.points = value;
    },
    SET_POINTS_CLIENTS(state, value) {
      state.pointsClients = value;
    },
    // Изменение selectedClient
    SET_STATE_POINTS_CLIENT: (state, value) => {
      state.filter.selectedClient = value;
      // localStorage.setItem(filterName, JSON.stringify(state.filter));
    },
    // Изменение selectedTonnage
    SET_STATE_POINTS_TONNAGE: (state, value) => {
      state.filter.selectedTonnage = value;
      // localStorage.setItem(filterName, JSON.stringify(state.filter));
    },
    // Изменение selectedBoxType
    SET_STATE_POINTS_BOX_TYPE: (state, value) => {
      state.filter.selectedBoxType = value;
      // localStorage.setItem(filterName, JSON.stringify(state.filter));
    }
  },
  actions: {
    addPoints({ commit }, value) {
      commit("SET_POINTS", value);
    },
    addPointsClients({ commit }, value) {
      commit("SET_POINTS_CLIENTS", value);
    },
    // Добавление selectedClient
    addFilterPointClient({ commit }, value) {
      commit("SET_STATE_POINTS_CLIENT", value);
    },
    // Добавление selectedTonnage
    addFilterPointTonnage({ commit }, value) {
      commit("SET_STATE_POINTS_TONNAGE", value);
    },
    // Добавление selectedBoxType
    addFilterPointBoxType({ commit }, value) {
      commit("SET_STATE_POINTS_BOX_TYPE", value);
    }
  }
};
